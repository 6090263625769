import io from 'socket.io-client'
import { useState, useEffect,useRef } from 'react'
import apiService from '../../services/api'
import { API_BASE_URL } from '../../config/constants'
import { useSocket } from '../../contexts/socketContext'

// const socket = io(`${API_BASE_URL}`)

function ChatComponent({newMessagesFrom=[],newMsgSetter}) {
	const socket = useSocket()
	const [messages, setMessages] = useState([])
	const [message, setMessage] = useState('')
	const [users, setUsers] = useState([])
	const [selectedUser, setSelectedUser] = useState(null)
	const [selectedUserType, setSelectedUserType] = useState('distributor')
	const textAreaRef = useRef(null)
	const [isRecording, setIsRecording] = useState(false);
	const [sendRrcordingCallback, setSendRecordingCallback] = useState(false);
	const [audioInfo, setAudioInfo] = useState(null);
	const mediaRecorderRef = useRef(null);
	const audioChunksRef = useRef([]);
	const [view, setView] = useState('userList') // 'userList' or 'chatMessages'
	const user = JSON.parse(decodeURIComponent('' + localStorage.getItem('transporters_user')))
	const userId = `user-${user.id}`

	useEffect(() => {
		const fetchUsers = async () => {
			try {
				const response = await apiService.get('transporters/getChatUsers')
				console.log('fetch response:',response.data)
				sortArrayByNewMessages(response.data,newMessagesFrom)
				setUsers(response.data)
			} catch (error) {
				console.error('Error fetching users:', error)
			}
		}
		console.log('socket in chat component',socket)
		fetchUsers()
		console.log('join_chat_room', `user:${userId}`)
		socket.emit('join_chat_room', `user:${userId}`)
		socket.on('receive_message', (data) => {
			console.log('receive_message', data)
			if(data.senderUsername) data.senderId = data.senderUsername
			setMessages((prevMessages) => [...prevMessages, data])
		})
		socket.on('ack_of_message', (data) => {
			console.log('ack_of_message', data)
		})

		return () => {
			socket.off('receive_message')
		}
	}, [])

	useEffect(() => {
		if (selectedUser) {
			console.log('selectedUser', selectedUser)
			newMsgSetter(newMessagesFrom.filter(u=>u!=selectedUser.username))
			const fetchMessages = async () => {
				try {
					const response = await apiService.get(`transporters/chatMessages/${selectedUser.id}`)
					console.log(response)
					setMessages(response.data)
				} catch (error) {
					console.error('Error fetching messages:', error)
				}
			}

			fetchMessages()
		}
	}, [selectedUser])

	const handleUserClick = (user) => {
		setMessages([])
		setSelectedUser(user)
		setView('chatMessages')
	}

	const handleBackClick = () => {
		setSelectedUser(null)
		setView('userList')
	}

	const sortArrayByNewMessages = (arr, newMessagesFrom) =>{
		return arr.sort((a, b) => {
		  const aHasMessage = newMessagesFrom.includes(a.username);
		  const bHasMessage = newMessagesFrom.includes(b.username);
	  
		  // Items with usernames in newMessagesFrom come first
		  if (aHasMessage && !bHasMessage) return -1;
		  if (!aHasMessage && bHasMessage) return 1;
	  
		  // Maintain original order if both are in or not in newMessagesFrom
		  return 0;
		});
	}
	const convertBase64ToAudioUrl = (base64Audio, mimeType) => {
		const audioBlob = base64ToBlob(base64Audio, mimeType);
		return URL.createObjectURL(audioBlob);
	  };
	  const base64ToBlob = (base64, contentType, sliceSize = 512) => {
		const byteCharacters = atob(base64);
		const byteArrays = [];
		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		  const slice = byteCharacters.slice(offset, offset + sliceSize);
		  const byteNumbers = new Array(slice.length);
		  for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		  }
		  const byteArray = new Uint8Array(byteNumbers);
		  byteArrays.push(byteArray);
		}
		return new Blob(byteArrays, {
		  type: contentType
		});
	  };
	  const startRecording = async () => {
		// First, ensure any existing stream is stopped to avoid multiple active streams
		if (mediaRecorderRef.current && mediaRecorderRef.current.stream) {
			mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
		  }
		
		  try {
			// Request a new user media stream with audio
			const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			mediaRecorderRef.current = new MediaRecorder(stream);
			audioChunksRef.current = [];
		
			mediaRecorderRef.current.ondataavailable = (event) => {
			  audioChunksRef.current.push(event.data);
			};
		
			mediaRecorderRef.current.start();
			setIsRecording(true);
		  } catch (error) {
			console.error('Error accessing the microphone:', error);
		  }
		
	  };
	  const stopRecording = async () => {
		mediaRecorderRef.current.stop();
		// mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
		setIsRecording(false);
		mediaRecorderRef.current.onstop = async () => {
		  const audioBlob = new Blob(audioChunksRef.current, {
			type: 'audio/webm'
		  });
		  const audioUrl = URL.createObjectURL(audioBlob);
		  const base64 = await blobToBase64(audioBlob);
		  const duration = await getAudioDuration(audioBlob);
		  setAudioInfo({
			base64: base64.toString().split(',')[1].trim(),
			mimeType: audioBlob.type,
			duration
		  });
		};
	  };
	  const blobToBase64 = blob => {
		return new Promise((resolve, reject) => {
		  const reader = new FileReader();
		  reader.readAsDataURL(blob);
		  reader.onloadend = () => {
			resolve(reader.result);
		  };
		  reader.onerror = error => {
			reject(error);
		  };
		});
	  };
	  const getAudioDuration = blob => {
		if (!(blob instanceof Blob)) {
		  throw new Error("The provided value is not a Blob.");
		}
		const audioContext = new (window.AudioContext || window.webkitAudioContext)();
		return new Promise((resolve, reject) => {
		  const reader = new FileReader();
		  reader.readAsArrayBuffer(blob);
		  reader.onloadend = () => {
			const arrayBuffer = reader.result;
			audioContext.decodeAudioData(arrayBuffer, audioBuffer => {
			  resolve(audioBuffer.duration * 1000); // Convert seconds to milliseconds
			}, e => {
			  reject('Error decoding audio data: ' + e.err);
			});
		  };
		  reader.onerror = error => {
			reject('File reading error: ' + error);
		  };
		});
	  };
	  const deleteRecording = () => {
		setIsRecording(false);
		setAudioInfo(null);
	  };

	  const sendMessage = async () => {
		if ((!message || message.length < 1) && !audioInfo) return;
		const messageData = {
		  senderId: userId,
		  receiverId: `user-${selectedUser.id}`,
		  message: message,
		  timestamp: new Date()
		};
		if (audioInfo && audioInfo.duration) {
		  messageData.message = `wtaudio:id:${new Date().getTime()}`;
		  messageData.audio = audioInfo.base64;
		  messageData.audioType = audioInfo.mimeType;
		  messageData.audioDuration = audioInfo.duration;
		}
		console.log(messageData);
		socket.emit('send_message', messageData);
		messageData.senderId = user.username;
		setMessages(prevMessages => [...prevMessages, messageData]);
		setMessage("");
		setTimeout(() => {
		  textAreaRef.current.selectionStart = 0;
		  textAreaRef.current.selectionEnd = 0;
		  textAreaRef.current.focus();
		  setAudioInfo(null);
		  setIsRecording(false);
		}, 10);
	  };
	  const formatMsgTimetamp = date => {
		let d = new Date(date);
		return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')} ${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}`;
	  };
	  const formatDuration = ms => {
		const seconds = Math.floor(ms / 1000 % 60);
		const minutes = Math.floor(ms / (1000 * 60) % 60);
		return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
	  };

	  useEffect(() => {
		return () => {
		  if (mediaRecorderRef.current && mediaRecorderRef.current.stream) {
			mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
		  }
		};
	  }, []);
	return (
		<>
			{view === 'userList' && (
				<div>
					{
						user?.role?.type=="store" ? 
							<h5 style={{fontSize: '14px', padding: '3px', textAlign: 'center', background: '#eaeaea', margin:0}}>Διαθέσιμοι διαχειριστές</h5>
						:

							<h5 style={{fontSize: '14px', display: 'flex', background: '#eaeaea60', justifyContent:'space-between', alignitems: 'center'}}>
								<span onClick={()=>{setSelectedUserType('distributor')}} style={{ padding:'3px',backgroundColor:selectedUserType=="distributor"?'#c7c7c7':'transparent',cursor:'pointer', textAlign:'center', width:'100%'}}>Διανομείς
									{
										users.filter(u=>u.role.type=="distributor" && newMessagesFrom.includes(u.username)).length>0 
										? <div className='chatNewMsg userType'></div> : ''
									}
								</span>
								<span onClick={()=>{setSelectedUserType('store')}} 	  style={{ padding:'3px',backgroundColor:selectedUserType=="store"?'#c7c7c7':'transparent',cursor:'pointer', textAlign:'center', width:'100%'}}>Καταστήματα
									{
										users.filter(u=>u.role.type=="store" && newMessagesFrom.includes(u.username)).length>0 
										? <div className='chatNewMsg userType'></div> : ''
									}
								</span>
							</h5>
					}
					<ul style={{ listStyle: 'none', padding:'0 1em' }} className='livechat-users-list'>
						{
							user?.role?.type=="store" ? (
								// list for store chat here
								users.map((user) => (
									<li key={user.id} style={{ cursor: 'pointer', marginBottom: '10px', fontWeight: newMessagesFrom.includes(user.username) ? 'bold' : 'normal' }} onClick={() => handleUserClick(user)}>
										{user.firstname ? (user.firstname+' '+user.lastname) : user.username}
										{newMessagesFrom.includes(user.username) ? <div className='chatNewMsg user'></div> : ''}
									</li>
								))
							) : (
								//list for admin chat here
								users.map((user) => {

									if (user.role.type==selectedUserType) return (
										<li key={user.id} style={{ cursor: 'pointer', marginBottom: '10px', fontWeight: newMessagesFrom.includes(user.username) ? 'bold' : 'normal' }} onClick={() => handleUserClick(user)}>
											{user.firstname ? (user.firstname+' '+user.lastname) : user.username}
											{newMessagesFrom.includes(user.username) ? <div className='chatNewMsg user'></div> : ''}
										</li>
									)
								}
								)
							)
						}
					</ul>
				</div>
			)}
			{view === 'chatMessages' && selectedUser && (
				<>
						<h5 style={{fontSize: '14px', padding: '3px',textAlign: 'center', background: '#eaeaea',  margin: '0', cursor: 'pointer', display: 'flex', justifyContent: 'space-between'}} onClick={handleBackClick}><span>{selectedUser.username}</span>← Πίσω στην λίστα χρηστών</h5>

					<div className="trannsporters-chat-inner">
					<div className="transporters-chat-messages">
							{messages.map((msg, index) =>
								msg.content ? (
								<div key={index} className={'msgBubble '+(msg.sender==user.username?'self' : '')}>
									<div className={!msg.audio ? 'text' : ''}>
										{msg.audio && (
											<audio src={convertBase64ToAudioUrl(msg.audio,msg.audioType)} controls style={{maxHeight:'25px',maxWidth:'190px'}}>
											Your browser does not support the audio element.
											</audio>
										)}
										{!msg.audio && msg.content}
										{formatMsgTimetamp(msg.timestamp)}
									</div>
								</div>
								) : (
								<div key={index}  className={'msgBubble '+(msg.senderId==user.username?'self' : '')}>
												
									{msg.audio && (
										<audio src={convertBase64ToAudioUrl(msg.audio,msg.audioType)} controls style={{maxHeight:'25px',maxWidth:'190px'}}>
											Your browser does not support the audio element.
										</audio>
									)}
									<div className={!msg.audio ? 'text' : ''}>
										{!msg.audio && msg.message}
									</div>
									{formatMsgTimetamp(msg.timestamp)}
								</div>
								)
							)}
						</div>
						<div className="transporters-message-bar">
							<textarea
								ref={textAreaRef}
								onKeyDown={(e) => {
								if (e.key === 'Enter') {
									sendMessage()
								}
								}}
								onChange={(e) => {
								setMessage(e.target.value)
								}}
								disabled={isRecording || audioInfo}
								value={isRecording ? 'Εγγραφή  ήχου...' : (audioInfo ? `Ηχητικό μήνυμα ${audioInfo && audioInfo.duration ? formatDuration(audioInfo.duration) : ''}` : message)}
							></textarea>
							{!audioInfo && (<button onClick={isRecording ? stopRecording : startRecording} className={isRecording ? 'chat-btn-stop-record' : 'chat-btn-record'}></button>)}
							{!isRecording && audioInfo && (<button onClick={deleteRecording} className={'chat-btn-del-record'}></button>)}
							
							<button onClick={isRecording ? stopRecording : sendMessage} className='chat-btn-send'></button>
						</div>
					</div>
				</>
			)}
		</>
	)
}

export default ChatComponent
