import React, { FC, ReactElement, ReactNode, useContext, useEffect, useLayoutEffect, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useMeasure, useWindowSize } from 'react-use'
import ThemeContext from '../../contexts/themeContext'
import Button from '../../components/bootstrap/Button'
import Portal from '../Portal/Portal'
import useDarkMode from '../../hooks/useDarkMode'
import apiService from '../../services/api'
import SOSimg from '../../assets/sos.jpg'
import MSGimg from '../../assets/message.png'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useSocket } from '../../contexts/socketContext'

interface IHeaderLeftProps {
	children: ReactNode
	className?: string
}
export const HeaderLeft: FC<IHeaderLeftProps> = ({ children, className }) => {
	return <div className={classNames('header-left', 'col-md', className)}>{children}</div>
}
HeaderLeft.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
}
HeaderLeft.defaultProps = {
	className: undefined,
}

interface IHeaderRightProps {
	children: ReactNode
	className?: string
}
export const HeaderRight: FC<IHeaderRightProps> = ({ children, className }) => {
	const [ref, { height }] = useMeasure<HTMLDivElement>()

	const root = document.documentElement
	root.style.setProperty('--header-right-height', `${height}px`)

	return (
		<div ref={ref} className={classNames('header-right', 'col-md-auto', className)}>
			{children}
		</div>
	)
}
HeaderRight.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
}
HeaderRight.defaultProps = {
	className: undefined,
}

interface IHeaderProps {
	children: ReactElement<IHeaderLeftProps> | ReactElement<IHeaderRightProps> | ReactNode
	hasLeftMobileMenu?: boolean
	hasRightMobileMenu?: boolean
}
const Header: FC<IHeaderProps> = ({ children, hasLeftMobileMenu, hasRightMobileMenu }) => {
	const { themeStatus } = useDarkMode()
	const userData = JSON.parse(decodeURIComponent('' + localStorage.getItem('transporters_user')))
	const windowsWidth = useWindowSize().width
	const [refMobileHeader, sizeMobileHeader] = useMeasure<HTMLDivElement>()
	const [refHeader, sizeHeader] = useMeasure<HTMLDivElement>()
	const [shiftManager, setShiftManager] = useState('')

	const root = document.documentElement
	root.style.setProperty('--mobile-header-height', `${sizeMobileHeader.height}px`)
	root.style.setProperty('--header-height', `${sizeHeader.height}px`)

	const { asideStatus, setAsideStatus, leftMenuStatus, setLeftMenuStatus, rightMenuStatus, setRightMenuStatus } = useContext(ThemeContext)

	useLayoutEffect(() => {
		if ((asideStatus || leftMenuStatus || rightMenuStatus) && windowsWidth < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)) document.body.classList.add('overflow-hidden')
		return () => {
			document.body.classList.remove('overflow-hidden')
		}
	})

	useEffect(() => {
		apiService.get(`transporters/option-get/shift_admin_id`).then((response: any) => {
			console.log(response.data)
			const userId = response.data.value
			apiService.get(`users/${userId}`).then((res: any) => {
				setShiftManager(`${res.data.firstname} ${res.data.lastname} - Τηλ : ${res.data.phone}`)
			})
		})
	}, [])

	const sendSOSRequest = () => {
		withReactContent(Swal)
			.fire({
				icon: 'question',
				html: (
					<>
						<h4>Αποστολή σήματος SOS σε όλους τους διανομείς;</h4>
						<p>
							<input
								id="sos_msg"
								type="text"
								style={{
									width: '100%',
									padding: '5px',
									borderRadius: '10px',
									border: '1px solid #eee',
									marginTop: '20px',
								}}
								placeholder="Συμπληρώστε μήνυμα SOS εδώ"
							></input>
						</p>
					</>
				),
				showDenyButton: true,
				denyButtonText: 'Άκυρο',
				confirmButtonText: 'ΑΠΟΣΤΟΛΗ SOS',
				preConfirm: async () => {
					const input: any = document.querySelector('#sos_msg')
					return input.value
				},
			})
			.then(async (res) => {
				console.log('SOS res', res)
				if (res.isConfirmed) {
					Swal.fire({
						didOpen: async () => {
							Swal.showLoading()
							let resp = await apiService.post('transporters/send-sos', { message: res.value })
							console.log('sos resp', resp)
							Swal.fire({
								icon: 'success',
								text: 'Το μήνυμα SOS στάλθηκε σε όλους.',
								timer: 3000,
							})
						},
					})
				}
			})
	}
	const sendMSGtoStores = () => {
		withReactContent(Swal)
			.fire({
				icon: 'question',
				html: (
					<>
						<h4>Αποστολή μηνύματος σε όλα τα καταστήματα;</h4>
						<p>
							<input
								id="stores_msg"
								type="text"
								style={{
									width: '100%',
									padding: '5px',
									borderRadius: '10px',
									border: '1px solid #eee',
									marginTop: '20px',
								}}
								placeholder="Συμπληρώστε μήνυμα εδώ"
							></input>
						</p>
					</>
				),
				showDenyButton: true,
				denyButtonText: 'Άκυρο',
				confirmButtonText: 'ΑΠΟΣΤΟΛΗ μηνύματος',
				preConfirm: async () => {
					const input: any = document.querySelector('#stores_msg')
					return input.value
				},
			})
			.then(async (res) => {
				console.log('MSG res', res)
				if (res.isConfirmed) {
					Swal.fire({
						didOpen: async () => {
							Swal.showLoading()
							let resp = await apiService.post('transporters/send-stores-msg', { message: res.value })
							console.log('sos resp', resp)
							Swal.fire({
								icon: 'success',
								text: 'Το μήνυμα στάλθηκε σε όλους.',
								timer: 3000,
							})
						},
					})
				}
			})
	}

	return (
		<>
			<header ref={refMobileHeader} className="mobile-header" style={{ backgroundColor: 'black' }}>
				<div className="container-fluid">
					<div className="row">
						<div className="col">
							<p style={{ color: '#fff' }} className="fs-3 mt-3">
								{userData.username}
							</p>
							{/* <Button
								aria-label="Toggle Aside"
								className="mobile-header-toggle"
								size="lg"
								color={asideStatus ? 'primary' : themeStatus}
								isLight={asideStatus}
								icon={asideStatus ? 'FirstPage' : 'LastPage'}
								onClick={() => {
									console.log('im herererer')
									// setAsideStatus(!asideStatus)
									setLeftMenuStatus(false)
									setRightMenuStatus(false)
								}}
							/> */}
							{hasLeftMobileMenu && (
								<Button
									aria-label="Toggle Left Menu"
									className="mobile-header-toggle"
									size="lg"
									color={leftMenuStatus ? 'primary' : themeStatus}
									isLight={leftMenuStatus}
									icon={leftMenuStatus ? 'MoreVert' : 'MoreHoriz'}
									onClick={() => {
										// setAsideStatus(!asideStatus)
										setLeftMenuStatus(!leftMenuStatus)
										setRightMenuStatus(false)
									}}
									style={{ display: 'none' }}
								/>
							)}
						</div>

						{hasRightMobileMenu && (
							<div className="col-auto" style={{ alignSelf: 'center' }}>
								<Button
									aria-label="Toggle Right Menu"
									className="mobile-header-toggle"
									size="lg"
									color={rightMenuStatus ? 'primary' : themeStatus}
									isLight={rightMenuStatus}
									icon={asideStatus ? 'Menu' : 'MenuOpen'}
									onClick={() => {
										setAsideStatus(!asideStatus)
										// setLeftMenuStatus(false)
										//setRightMenuStatus(!rightMenuStatus)
									}}
								/>
							</div>
						)}
					</div>
				</div>
			</header>
			<header
				ref={refHeader}
				className={classNames('header', {
					'header-left-open': leftMenuStatus,
					'header-right-open': rightMenuStatus,
				})}
			>
				<div className="container-fluid">
					<div className="row d-flex align-items-center">
						{userData.role.type !== 'store' ? (
							<div className="d-flex justify-content-start">
								<a className="btn btn-info" style={{ marginTop: '15px', marginLeft: '30px', position: 'absolute' }} rel="noopener" href="/orders/add-order">
									ΝΕΑ ΚΛΗΣΗ
								</a>
							</div>
						) : (
							''
						)}
						<div className="d-flex justify-content-end">
							{userData.role.type !== 'store' ? (
								<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
									<img src={MSGimg} alt="" width="40px" style={{ cursor: 'pointer', marginRight: '20px' }} onClick={sendMSGtoStores} />
									<img src={SOSimg} alt="" width={'50px'} style={{ cursor: 'pointer' }} onClick={sendSOSRequest} />
								</div>
							) : (
								<>Διαχειριστής Βάρδιας : {shiftManager}</>
							)}
						</div>
						{children}
						{(leftMenuStatus || rightMenuStatus) && (
							<Portal>
								<div
									role="presentation"
									className={classNames('header-overlay', {
										'header-overlay-left-menu': leftMenuStatus,
										'header-overlay-right-menu': rightMenuStatus,
									})}
									onClick={() => {
										setAsideStatus(true)
										setLeftMenuStatus(false)
										setRightMenuStatus(false)
									}}
								/>
							</Portal>
						)}
					</div>
				</div>
			</header>
		</>
	)
}
Header.propTypes = {
	children: PropTypes.node.isRequired,
	hasLeftMobileMenu: PropTypes.bool,
	hasRightMobileMenu: PropTypes.bool,
}
Header.defaultProps = {
	hasLeftMobileMenu: true,
	hasRightMobileMenu: true,
}

export default Header
